@import './vars';

/* font applied in whole body */
body {
    font-family: 'Poppins', sans-serif !important;
}

/* minimum height set for whole body */
#root {
    min-height: 100vh !important;
}

/* ---------------------------------------------------
    INPUT STYLE
----------------------------------------------------- */
input,
.form-select {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.verification-input {
    font-size: 25px !important;
    font-weight: 500 !important;
    border: 1px solid $primary !important;
    width: 2.2em !important;
    height: 2.2em !important;
}

.verification-input[value=""] {
    border-color: #C4C4C4 !important;
}

.form-control:focus,
.form-select:focus {
    border-color: #EAEAEA !important; // $primary !important;
    box-shadow: inset 0 1px 1px rgb(253, 254, 254, 0.075), 0 0 1px #EAEAEA !important; // $primary !important;
}

.search-input:focus,
.search-input:focus-within {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(253, 254, 254, 0.954), 0 0 2px #fff !important;
}

.input-group:focus-within {
    outline: 1px solid $primary !important;
}

.input-group:focus-within *:focus {
    border-color: transparent !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0px $primary !important;
}

/* ---------------------------------------------------
    FONT SIZE STYLE
----------------------------------------------------- */
.fs-8 {
    font-size: 8px !important;
}

.fs-9 {
    font-size: 9px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-35 {
    font-size: 35px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-45 {
    font-size: 45px !important;
}

.fs-60 {
    font-size: 60px !important;
}

/* ---------------------------------------------------
    FONT WEIGHT STYLE
----------------------------------------------------- */
.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

/* ---------------------------------------------------
    DISPLAY STYLE
----------------------------------------------------- */
.d-contents {
    display: contents !important;
}

/* ---------------------------------------------------
    FLEX STYLE
----------------------------------------------------- */
.flex-grow-5 {
    flex-grow: 5 !important;
}

/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */
.text-blue {
    color: #1D2939 !important
}

.footer-text {
    color: #475467 !important;
}

.white-break-spaces {
    white-space: break-spaces !important;
}

/* ---------------------------------------------------
    BUTTON STYLE
----------------------------------------------------- */
.btn-outline-primary:hover {
    color: white !important;
}

/* ---------------------------------------------------
    ACCORDION STYLE
----------------------------------------------------- */
.program-accordion {
    .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FA5000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
        margin-bottom: auto !important;
    }

    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FA5000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
        transform: rotate(-180deg) !important;
        margin-bottom: auto !important;
    }
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 310px;
    max-width: 310px;
    background: #EAECF0;
    // height: 100vh;
    margin-left: -310px;
    transition: all 0.5s;
    // height: 100% !important;

    ul p {
        color: #fff;
        padding: 10px;
    }

    .nav-item {
        color: #adb5bd;
        font-weight: 300;
    }

    .nav-item.open {
        color: #1D2939;
        background: #313b4c;
    }

    .nav-item.active {
        color: #1D2939;
        background: #fff !important;
    }

    .nav-item:hover {
        background: #fff;
        color: #1D2939;
        transition: .2s;
    }
}

.sidebar.is-open {
    margin-left: 0;
    transition: .5s;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%
}

/* ---------------------------------------------------
    TOURNAMENT STYLE
----------------------------------------------------- */
.tournament-category>input {
    display: none !important;
}

.event-type>input {
    display: none !important;
}

/* ---------------------------------------------------
    TIMEPICKER STYLE
----------------------------------------------------- */
.react-time-picker__clear-button {
    display: none !important;
}

.react-time-picker__clock-button__icon {
    stroke: #98A2B3 !important
}

.react-time-picker__inputGroup__input {
    margin-left: 0px !important;
    text-align: start !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    background: transparent !important;
}

.react-time-picker__inputGroup__hour {
    width: 12px !important;
}

.react-time-picker__inputGroup__leadingZero {
    font-size: 14px !important;
}

.react-time-picker__inputGroup__input:focus {
    outline: none !important;
}

.react-time-picker__wrapper {
    border: none !important;
}

.react-time-picker__inputGroup__amPm {
    width: 46px !important;
}

.react-time-picker__clock {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* ---------------------------------------------------
    DATEPICKER STYLE
----------------------------------------------------- */
.react-datepicker__input-container>div {
    margin-bottom: 0 !important;
}

/* ---------------------------------------------------
    PROFILE POPOVER STYLE
----------------------------------------------------- */
.profile-popover {
    max-width: 250px !important;
    width: 250px !important;
}

/* ---------------------------------------------------
    SWEETALERT STYLE
----------------------------------------------------- */
.swal2-confirm {
    display: none !important;
}

.swal2-html-container {
    margin: 0 !important;
}

.swal2-popup {
    padding: 0 !important;
}

.swal2-actions {
    display: none !important;
}

.swal2-close:focus {
    box-shadow: none !important;
}

/* ---------------------------------------------------
    BACKGROUND COLOR STYLE
----------------------------------------------------- */
.bg-opacity-50 {
    --bs-bg-opacity: 0.5 !important;
}

/* ---------------------------------------------------
    PROFILE PHOTO STYLE
----------------------------------------------------- */
.profile-photo {
    width: 108px !important;
    height: 108px !important;
}

.profile-small-photo {
    width: 37px !important;
    height: 37px !important;
}

/* ---------------------------------------------------
    CAROUSEL STYLE
----------------------------------------------------- */
.home-carousel {
    .carousel-caption {
        left: 0% !important;
        right: 0% !important;
    }

    .carousel-image {
        height: 220px !important;
        object-fit: cover !important;
    }
}

/* ---------------------------------------------------
    BANK IMAGE STYLE
----------------------------------------------------- */
.bank-image {
    width: 80px !important;
    height: 80px !important;
    object-fit: contain !important;
}

/* ---------------------------------------------------
    TOURNAMENT STYLE
----------------------------------------------------- */
.tournament-banner {
    height: 320px !important;
    object-fit: cover !important;
}

/* ---------------------------------------------------
    REACT DATA TABLE COMPONENT STYLE
----------------------------------------------------- */
.wallet-transaction {
    .rdt_TableHead {
        display: none !important;
    }
}

.gmlwXk {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.py-point-1_5 {
    padding-top: .15rem !important;
    padding-bottom: .15rem !important;
}

.bg-opacity-20 {
    --bs-bg-opacity: 0.2 !important;
}

.line-height-20 {
    line-height: 20px !important;
}