/* ---------------------------------------------------
    SIDEBAR CONTENT STYLE
----------------------------------------------------- */
@media only screen and (max-width: 991px) {
    .content.is-open {
        display: none;
        transition: all 0.5s;
    }
  
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
        height: 100vh !important;
    }
  
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

/* ---------------------------------------------------
    WIDTH STYLE
----------------------------------------------------- */
@media only screen and (max-width: 575px) {
    .w-xs-100 {
        width: 100% !important;
    }
}

/* ---------------------------------------------------
    MARGIN STYLE
----------------------------------------------------- */
@media only screen and (max-width: 575px) {
    .me-xs-0 {
        margin-right: 0 !important;
    }
}
