/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 1025px) {
  .box-text .box-title {
    font-size: 13px;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 991px) {
  .bannerSection {
    height: 900px;
  }
  .client-feedback .list-feedback .item-feedback .contents {
    border-radius: 15px 15px 0px 0px;
  }
  .client-feedback .list-feedback .item-feedback .profile-image img {
    border-radius: 0px 0px 15px 15px;
  }
  .w-50-freq { width: 100%;}
  .frequency-adjust {
    flex-direction: column;
  }
  html {
    padding: 10px;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 767px) {
  .description-common-height-coach {
    height: auto;
  }
  .description-common-height {
    height: 40;
    max-height: 50px;
  }
  .top-coaches-common-height {
    height: 50px;
    max-height: 50px;
  }
  .client-feedback .list-feedback .item-feedback .profile-image img {
    max-height: 295px;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (min-width: 576px) {
  #footerMobileOnly {
    display: none;
  }
  #mobileMenuOnly {
    display: none;
  }
  #box-coach-mobile-only {
    display: none;
  }
  #coach-button-mobile {
    display: none;
  }
  .mobileBox {
    width: 80%;
    margin: auto;
  }
  .student-single-pro .web-visible-image {
    display: block;
  }
  .student-single-pro .mobile-visible-image {
    display: none;
  }
  .web-not-fount {
    display: block;
  }
  .mobile-not-fount {
    display: none;
  }
  .cancel-web{ display: block; }
  .cancel-mobile{ display: none; }
}
/* On screens that are 480px or less, set the background color to olive */
@media screen and (max-width: 480px) {
  .client-feedback .list-feedback .item-feedback .contents {
    padding: 45;
  }
  .client-feedback .list-feedback .item-feedback .contents .message {
    font-size: 20px;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 575px) {
  #footerWebOnly {
    display: none;
  }
  #headerWebOnly {
    display: none;
  }
  #box-coach-web-only {
    display: none;
  }
  #coach-button-web {
    display: none;
  }
  .bannerSection {
    height: 750px;
  }
  .student-register p.title {
    font-size: 24px !important;
  }
  .banner {
    width: 300px;
    height: 250px;
    margin: auto;
  }
  .bannerImage {
    width: 300px;
    height: 250px;
    margin: auto;
  }
  .header-slag {
    font-size: 30px;
    font-weight: 600;
  }
  .boxSection .boxTop {
    margin-top: 0px;
  }
  .boxSection .box {
    margin-bottom: 15px;
  }
  .programme-box .session-box {
    width: auto;
  }
  .programme-image img {
    width: 280px;
    max-width: 300px;
    margin: auto;
  }

  .DetailBanner {
    padding: 40px;
  }
  .DetailBanner .programme-name h1 {
    font-size: 25px;
  }
  .only-desktop-visible {
    display: none;
  }
  .student-single-pro .web-visible-image {
    display: none;
  }
  .student-single-pro .mobile-visible-image {
    display: block;
  }
  .box-cover {
    border: 0px;
    border-radius: 0px;
  }
  .single-programe .custom-tab.student-tabs ul li button {
    width: 100px;
  }
  .single-programe .custom-tab.student-tabs .nav-tabs .nav-link.active {
    width: 100px;
  }
  .client-feedback .list-feedback .item-feedback .contents {
    border-radius: 15px 15px 0px 0px;
  }
  .client-feedback .list-feedback .item-feedback .profile-image img {
    border-radius: 0px 0px 15px 15px;
  }
  .client-feedback .list-feedback .item-feedback .profile-image img {
    max-height: 260px;
  }
  .web-not-fount {
    display: none;
  }
  .mobile-not-fount {
    display: block;
  }
  .cancel-web{ display: none; }
  .cancel-mobile{ display: block; }
}
