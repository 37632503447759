.section__Jobs-styledTab { padding-left: 0px;}
.section__Jobs-buttonCompany {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  border-left: 2px solid #fa5000;
  outline: none;
  /* font-family: "Roboto Mono", monospace; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 42px;
  width: 100%;
  text-align: left;
  padding: 0 20px;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:focus {
  background-color: #fa5000;
  color: #1d2939;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:active,
.section__Jobs-buttonCompany:focus {
  color: #1d2939;
  background-color: #FFEEE6;
  /* font-family: "Poppins"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  outline: 0px;
}
