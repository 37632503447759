html {}

body {
  background-color: #fff !important;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
}

/* Loader style */
.parent {
  margin: auto;
}

.myHeight {
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.myCapitalized {
  text-transform: capitalize;
}

.btn-close:focus {
  box-shadow: 0px 0px 0px 0px rgba(16, 24, 40, 0.05) !important;
}

/* .btn:hover {
  color: #fff !important;
} */
/* Header Css */
.coachingHeader .nav-item.dropdown {
  margin: 0px 10px 0px 20px;
  font-size: 16px;
}

.coachingHeader .navbar-nav .price {
  margin: 0px 0px 0px 20px;
  font-size: 16px;
}

.coachingHeader .dropdown-menu.show {
  font-size: 16px;
}

.select__indicator-separator {
  display: none;
}

.coachingHeaderRight .headerButton {
  font-size: 16px;
}

.coachingHeaderRight .common-sign-in {
  border: 1px solid #d0d5dd !important;
  color: #475467 !important;
}

.btn-outline-secondary:hover {
  background-color: #fa5000 !important;
  color: #fff !important;
  border: 1px solid #fa5000;
}

.bellIcon {
  width: 40px;
  padding: 6px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid var(--grey-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* Mobile View Menu Css */
.mobileMenu .nav-item.dropdown {
  margin: 0px 24px 0px 0px;
  font-size: 14px;
  font-weight: 500;
}

.mobileMenu .navbar-nav .price {
  margin: 0px 0px 0px 10px;
  font-size: 14px;
  font-weight: 500;
}

.mobileMenu .navbar-nav .dropdown-menu {
  position: absolute !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #fc6a26 !important;
  color: #fff !important;
}

.btn-outline-secondary {
  border: 1px solid #667085 !important;
  color: #667085 !important;
}

/* Header Banner CSS */

.bannerSection {
  padding-top: 50px;
  width: 100%;
  height: 550px;
  min-height: 400px;
  background-color: #ffeee6;
}

.FooterBg {
  background-color: #f2f4f7;
}

.student-register input {
  height: 41px;
}

.buttonBG {
  background-color: #ffeee6 !important;
}

.student-signIn {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}

.banner {
  width: 420px;
  height: 420px;
  margin: auto;
}

.bannerImage {
  width: 420px;
  height: 420px;
  margin: auto;
}

.header-slag {
  font-size: 48px;
  font-weight: 600;
}

.header-search {
  height: 70px !important;
  border: 1px solid #fff !important;
  border-radius: 16px;
  padding: 13px 20px 16px 20px;
  background-color: #fff;
}

.header-search .search-input {
  height: 40px !important;
  border: 1px solid #fff !important;
}

.header-search .search-input .input-group:focus-within {
  outline: 1px solid #fff !important;
}

.header-search .search-input .input-group:focus-within *:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* .input-group:focus-within {
  outline: none !important;
} */

.header-search .search-input button {
  height: 40px !important;
  border: 1px solid #fa5000 !important;
  border-radius: 100%;
  color: #fff;
}

.header-search .search-input .form-control {
  border: 1px solid #fff !important;
}

.header-search .search-input::before {}

.header-search .search-input::after {}

.trusted p {
  font-size: 18px;
  color: #667085;
}

.count .plus {
  font-size: 40px;
  font-weight: 600;
}

.count s.review {
  font-size: small;
}

.boxSectionCoach {
  background-color: #f9fafb;
}

.boxSection .box {
  display: flex;
  padding: 14px 24px;
  align-items: center;
  gap: 30px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--base-white, #fff);
  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.boxSection .box .image {
  width: 80px;
  height: 80px;
  background: #fa5000;
  border-radius: 100%;
  padding: 10px;
  border: 10px solid #ffeee6;
}

.boxSection .box .image img {
  width: 40px;
}

.boxSection .boxTop {
  margin-top: -53px;
}

.programme-box .session-box {
  width: "18rem";
}

.programme-image img {
  width: 400px;
  max-width: 400px;
  max-height: 250px;
}

.description-common-height {
  /* height: 65px; */
  /* max-height: 70px; */
  color: #1d2939;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.description-common-height-coach {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  max-height: 50px;
  color: #1d2939;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.top-coaches-common-height {
  height: 60px;
  max-height: 65px;
  color: #1d2939;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.box-text .box-title {
  font-size: 15px;
}

.customBox .card {
  border: 1px solid #fff !important;
  box-shadow: 0px 2px 4px -2px rgb(16 24 40 / 17%),
    0px 4px 8px -2px rgb(16 24 40 / 18%);
  /* 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1); */
  border-radius: 14px;
}

.boxSection .box-programme .customBox a {
  text-decoration: none !important;
}

.student-pagination {
  padding: 0px 12px 0px 12px;
}

.customBox .card .card-img,
.card-img-top {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.section__Jobs-styledTabList {
  padding-left: 0px !important;
}

.profile_pic img {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-radius: 100%;
}

.certificate {
  border-radius: 16px;
  background: var(--blue-gray-50, #f8f9fc);
  mix-blend-mode: multiply;
  color: #363f72;
  text-align: center;
  font-size: 14px;
  width: 182px;
  font-weight: 600;
}

.faq-list .accordion-item {
  border: 0px solid #fff !important;
}

.faq-list .accordion {
  border-bottom: 1px solid #eaecf0 !important;
}

.faq-list .accordion-item .accordion-header button {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.faq-list .accordion-item .accordion-header button:focus {
  border-color: 1px solid #eaecf0 !important;
}

.faq-list .accordion-item .accordion-header .accordion-button::-moz-focus-inner {
  border-color: 1px solid #eaecf0 !important;
}

/* *:focus {
  outline: none !important;
  box-shadow: none !important;
} */

.accordion-button:focus,
.accordion-button:focus-within {
  border-color: #eaecf0 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  border: 2px;
}

.faq-list .accordion-item .accordion-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #101828 !important;
}

/* Faq Icon */
.accordion-button::after {
  background-image: url('../images/svg/faq/faq-accordion-plus.svg') !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../images/svg/faq/faq-accordion-minus.svg") !important;
}

/* Programme css */

.session-box .course-price {
  color: #fff !important;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12.5px;
  top: 112px;
  border-radius: 100px;
  background: var(--primary-500, #fa5000);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  word-break: break-all;
  width: 80px;
  height: 80px;
  text-align: center;
}

.session-box .custom-chip-box {
  position: absolute;
  top: 95px;
}

.session-box .custom-chip-box .custom-chip-danger {
  background-color: #d92d20;
  padding: 3px 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  width: 70px;
  border-radius: 24px;
  text-align: center;
}

.session-box .custom-chip-box .custom-chip-success {
  background-color: #039855;
  padding: 3px 12px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  width: 70px;
  border-radius: 24px;
  text-align: center;
}

.session-box .program-des {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #1d2939;
}

.DetailBanner {
  padding: 50px;
}

.DetailBanner .programme-image img {
  border-radius: 18px;
}

.DetailBanner .programme-name h1 {
  font-size: 36px;
  font-weight: 800;
}

/* .DetailBanner .programme-description p,
span {
  font-size: 12px;
  font-weight: 400;
  color: #475467;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
} */

.showMoreText span {
  font-size: 16px !important;
  text-align: justify !important;
}

.publish-unpublish-btn span {
  font-size: 16px;
  font-weight: 400;
  color: #475467;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.DetailBanner .programme-button .disable {
  background-color: #ffeee6 !important;
  border: 1px solid #344054 !important;
  color: #344054;
}

.DetailBanner .programme-button .disable:hover {
  color: #344054;
}

.student-learn .description-learn div p {
  font-size: 18px;
  font-weight: 400;
  color: #475467;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.overview-section .programme-title {
  font-size: 21px;
  font-weight: 600;
  color: #101828;
}

.box-coach .coach-profile img {
  height: 60px;
  border-radius: 100%;
}

.overview-section .programme-list .list ul {
  font-size: 18px;
  font-weight: 600;
  color: #475467;
}

.overview-requirement {
  background-color: #f9fafb;
}

.overview-pricing {
  padding: 32px 0px 108px 0px;
  background-color: #fa5000;
  color: #fff;
}

.overview-pricing-box {
  margin-top: -113px;
}

.overview-pricing-box .price-box {
  padding: 22px;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;
  /* Shadow/md */
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.overview-pricing-box .price-box .monthly-plan {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.overview-pricing-box .price-box .monthly-price {
  /* font-size: 28px; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #1d2939;
}

.overview-pricing-box .paid-image img {
  width: 115px;
  margin: -27px;
  position: relative;
}

.overview-pricing-box .price-box .per-session {
  color: #667085;
  /* Text md/Regular */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.student-count {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 7px;
  border-radius: 12px;
  color: #fa5000;
  background-color: #ffeee6;
  margin-left: 10px;
  text-align: center;
}

.student-list-table .rdt_TableHeadRow {
  background: #f9fafb;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #eaecf0;
}

.custom-calendar {
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid var(--grey-300, #d0d5dd);
  background: var(--base-white, #fff);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.custom-tab.student-tabs .nav-link {
  color: #667085 !important;
}

.qsingle-programe .custom-tab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  /* padding: 7px 50px !important; */
  width: 180px;
  color: #fa5000 !important;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  border-radius: 6px;
  background: #fff;
  font-size: 14px;
}

.single-programe .custom-tab.student-tabs .nav-tabs {
  /* border: 0px; f2f4f7 */
  background-color: #f5f5f5;
  /* padding: 4px 5px 2px 7px; */
  padding: 4px 0px 2px 0px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

/* .single-programe .custom-tab.student-tabs {} */
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #f5f5f5 !important;
}

.single-programe .custom-tab.student-tabs ul li button {
  width: 180px;
  text-align: center;
}

.single-programe .custom-tab.student-tabs .nav-tabs .nav-link.active {
  width: 180px;
  color: #fa5000 !important;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  border-radius: 6px;
  background: #fff;
  font-size: 14px;
}

.single-programe .custom-tab ul .nav-item {
  /* padding: 1px 10px; */
  background: #f5f5f5;
  margin: 0px 6px 0px 6px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #f5f5f5;
}

/* .custom-tab .custom-tab .nav-tabs .nav-link.active{ padding: 10px 50px !important;} */
.coach-detail .coach-name {
  font-size: 24px;
  color: #101828;
  font-weight: 800;
}

.coach-detail .profile-contact {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coach-detail .profile-description {
  color: var(--grey-500, #667085);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.coach-detail .password {
  color: var(--grey-800, #1d2939);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coach-view-box {
  padding: 10px 12px;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--grey-200, #eaecf0);
  margin-bottom: 10px;
}

.coach-view-box .detail p {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}

.student-signIn .btn:hover {
  color: #fa5000 !important;
}

.frequency-list {
  padding: 24px;
  background: #f2f4f7;
}

.frequency-list .add-title {
  /* color: #fff; */
  color: #262626;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-content .btn:hover {
  background: #fa5000 !important;
  color: #fff !important;

}

.frequency-list .add-content:hover {
  cursor: pointer;
}

.frequency-list .cancel:hover {
  cursor: pointer;
}

.recurring-date .recurring-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter-sport ul {
  padding-left: 0px !important;
  display: flex;
  list-style: none;
  width: 100%;
  scrollbar-color: red orange;
}

.filter-sport ul li {
  font-size: 16px;
  display: inline;
  padding: 6px 15px;
  width: auto;
  float: left;
  text-align: center;
  margin: 0px 8px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--base-white, #fff);

  /* Shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.filter-custom-scroll {
  scroll-behavior: auto;
  overflow: scroll;
  scrollbar-width: thin;
}

.related-pro {
  scroll-behavior: auto;
  overflow: scroll;
  scrollbar-width: thin;
}

.filter-sport ul li:hover {
  background-color: #fa5000;
  color: #fff;
}

.filter-sport ul li:active {
  background-color: #fa5000;
  color: #fff;
}

.custom-active {
  background-color: #fa5000 !important;
  color: #fff;
}

.upload-icon {
  border-radius: 8px;
}

.program-card-image {
  height: 160px;
}

.program-card-image .card-img-top {
  height: 160px;
}

/* .session-times ul li button:hover {background-color: #fa5000; color: #fff;} */
.session-background {
  background-color: #f9fafb;
}

.session-section .section-start-image {
  max-width: 100%;
}

.session-section .section-start-image img {
  max-width: 100%;
}

.section-start-text .title {
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.section-start-text .description {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.session-column .child {
  /* width: 250px; */
}

a {
  text-decoration: none;
}

.banner-programme img {
  max-width: 450px;
}

.student-price .price {
  background-color: #fa5000;
}

.student-bottom-enrollment .custom-white-button button {
  background-color: #fff;
  border: 1px solid #1d2939;
  color: #1d2939 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 250px;
  line-height: 28px;
}

/* Checkout css */
.checkout .academy-name p {
  color: #fa5000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkout .programme-name p {
  color: #1d2939;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkout-programmes ul {
  padding: 0px;
  list-style: none;
}

.checkout-programmes ul li {
  padding: 10px;
  background-color: #ffeee6;
  color: #1d2939;
  padding: 10px 20px;
  margin-bottom: 12px;
  border-radius: 10px;
}

.checkout-programmes ul li p {
  margin-bottom: 8px;
}

.checkout-programmes ul li p .name {
  color: #fa5000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkout-payment {
  background-color: #f2f4f7;
  font-weight: 600;
  color: #344054;
  padding: 34px;
  margin-top: 15px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 100%;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #fa5000;
  border-radius: 100%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* My Programmes */
.box-my-programme a {
  color: #000;
  text-decoration: none;
}

.box-my-programme .session-box .card-img-left {
  width: 200px;
  min-height: 190px;
  border-radius: 8px 0 0px 8px;
}

@media screen and (max-width: 575px) {
  .box-my-programme .session-box .card-img-left {
    width: 100%;
    min-height: 190px;
  }
}

.parent-session.student-session {
  text-align: center;
}

.parent-session.student-session span {
  font-size: 13px;
}

.horizental-row .joined {
  text-align: end;
}

.profile-phone {
  flex-grow: 9 !important;
}

.academy-login .flex-grow-17 {
  flex-grow: 17 !important;
}

.student-login .flex-grow-12 {
  flex-grow: 10 !important;
}

.coach-login .flex-grow-12 {
  flex-grow: 12 !important;
}

.contact-page .flex-grow-19 {
  flex-grow: 19 !important;
}

.add-session-button button {
  background: #ffeee6 !important;
  border: 1px solid #ffeee6 !important;
  color: #fa5000 !important;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 25px;
  border-radius: 9px !important;
}

.add-session-button button:hover {
  background: #ffeee6 !important;
  border: 1px solid #ffeee6 !important;
  color: #fa5000 !important;
}

.export-button {
  background: #ffeee6 !important;
  border: 1px solid #ffeee6 !important;
  color: #fa5000 !important;
  font-family: "Poppins";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 15px;
  border-radius: 9px !important;
}

.student-single-pro .mobile-visible-image {
  display: none;
}

.profile-background {
  background-color: #fcfcfd;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

.user-profile-edit-phone {
  flex-grow: 20 !important;
}

.overview-requirement .programme-list pp {
  color: #667085;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.overview-requirement .description .programme-list p {
  color: #667085;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.academy-learn-description ul li {
  color: #475467 !important;
  /* Text lg/Regular */
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.academy-learn-description ul {
  /* // background-color: #fff !important; */
  border: none !important;
  border-radius: 0px !important;
  color: #475467 !important;
}

.box-cover {
  border: 1px solid #eaecf0;
  border-radius: 6px;
}

.give-rating-button {
  background-color: #fff;
  color: #1d2939;
  border: 1px solid #1d2939;
  /* Text lg/SemiBold */
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.coach-session-listing .single-session .session-show .session-count {
  color: #1d2939;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coach-session-listing .single-session .session-show .session-date {
  color: #1d2939;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coach-session-listing .single-session .session-show .time-slots .time-slot-button {
  color: #1d2939;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #667085;
  background: #fcfcfd;
}

.student-session-listing .single-session .session-show .time-slots .time-slot-button {
  color: #1d2939;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #667085;
  background: #fcfcfd;
}

.joined-session button {
  color: #fff;
  font-family: "Poppins";
  font-size: 15px;
  padding: 6px 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid #12b76a;
  background: #12b76a;
}

.student-heading h1 {
  color: #101828;
  /* Display lg/Semibold */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: normal;
}

.custom-hr {
  border: 1px solid #eaecf0;
}

.change-password {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  background-color: #fcfcfd;
}

.custom-hover {
  cursor: pointer;
}

.profile-background .section__Jobs-buttonCompany {
  font-weight: 800 !important;
}

/* client feedback */

.client-feedback .list-feedback .item-feedback-bothh {
  border: 1px solid #667085;
  border-radius: 15px;
}

.client-feedback .list-feedback .item-feedback .contents {
  /* border: 1px solid #667085; */
  border-radius: 15px 0px 0px 15px;
  padding: 60px;
  color: #fff;
  font-family: "Poppins";
  background-color: #20364b;
  /* #667085; */
}

.client-feedback .list-feedback .item-feedback .contents .message {
  color: #fff;
  /* Display md/Medium */
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.client-feedback .list-feedback .item-feedback .contents .name {
  color: #fff;
  /* Display md/Medium */
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.client-feedback .list-feedback .item-feedback .contents .slider-dots span {
  color: #fff;
  font-family: "Poppins";
  font-size: 25px;
  cursor: pointer;
}

.disabled_swiper_buttonB {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.client-feedback .list-feedback .item-feedback .profile-image img {
  border-radius: 0px 15px 15px 0px;
  /* border: 1px solid grey; */
  height: 350px;
  max-height: auto;
}

.css-aos99y-multiValue {
  background-color: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #d0d5dd;
}

/* .css-aos99y-multiValue { background-color: #fc6a26 !important; color: #fff !important; border-radius: 11px !important;} */
.ReactModal__Overlay--after-open {
  z-index: 10000 !important;
}

.opacity_0_6 {
  opacity: 0.6 !important
}

.frequency-content {
  /* background-color: #F9FAFB !important; */
}

.frequency-content .input-group-text {
  background-color: #fff;
}

.frequency-content .program-custom-hr {
  margin: 15px 0px 15px 0px;
  border-top: 1px solid #98a2b3;
}

/* ..btn:hover { color: #fff !important;} */
.not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-found h4 {
  color: #101828;
  /* Display lg/SemiBold */
  font-family: "Poppins";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}

.not-found .description {
  color: #667085;
  /* Text xl/Regular */
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.flex-grow-10 {
  flex-grow: 10;
}

.w-50-freq {
  width: 210px;
}

.cancel-web {
  margin-top: 45px;
}

.cancel-mobile {
  margin-top: 10px;
}

.react-switch-bg {
  background: rgb(251 80 0) !important;
}

/* Font Size Class */
.frequency-adjust {
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.guest.box-programme {
  padding: 0 2rem;
}

.student-my-programs {
  color: #1d2939;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.modal-session {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-join-session-button {
  background-color: #fff !important;
  border: 1px solid #1d2939;
  color: #1d2939 !important;
  border-radius: 8px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.price-cirle-color>p>span {
  color: #fff !important;
}

.modal-join-session-button:hover {
  background-color: #fa5000 !important;
  border: 1px solid #fa5000 !important;
  color: #fff !important;
}

.join-button {
  padding: 8px 44px;
  font-size: 1rem;
}

.text-green {
  color: #039855;
}

.text-grey {
  color: #475467;
}

.academy-date .flatpickr-wrapper {
  width: 87%;
}

.academy-date .form-control.input {
  border-radius: 6px 0px 0px 6px;
}

.update-student .flatpickr-wrapper {
  width: 79%;
}

.update-student .form-control.input {
  border-radius: 6px 0px 0px 6px;
}

.join-student .flatpickr-wrapper {
  width: 77%;
}

.join-student .form-control.input {
  border-radius: 6px 0px 0px 6px;
}

.flatpickr-wrapper {
  width: 93%;
}

.form-control.flatpickr-input {
  border-radius: 6px 0px 0px 6px;
}

.time-selection .flatpickr-wrapper {
  width: 80% !important;
}

.time-selection .flatpickr-wrapper .form-control.input {
  border-radius: 6px 0px 0px 6px !important;
}

.earningFlatPikr>.flatpickr-wrapper {
  width: 60% !important;
}

.earningFlatPikr>.flatpickr-wrapper>input:focus-visible,
.earningFlatPikr>.flatpickr-wrapper>input:focus {
  border: none !important;
  outline: none !important;
}

/* ganttChartFlatpickr */
.ganttChartFlatpickr>.flatpickr-wrapper {
  /* width: auto; */
  width: 15% !important;
}

.bg-ganttChartFlatpickr {
  background-color: #EAECF0 !important;
}

.calendarTop {
  display: none;
}

._3eULf>div {
  border: 1px solid #dfe1e5;
}

/* ---------------------- */

/* schedular with calendar */
.scheduler-view {
  width: 65vw !important;
}

/* ------------------- */

.height-80vh {
  height: 80vh !important;
}

.coach-select-session:hover {
  background-color: #fb621a !important;
  color: #fff !important;
}

/* Wrap with (...) after 2 line text */
.wrap_after_two_line {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

/* SELECT package border */
.select__control--is-disabled {
  border: none !important;
}

.contact-academy-image {
  max-width: 200px !important;
}